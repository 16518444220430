<template>
    <div class="height1">
        <!--      头部      -->
        <bread></bread>
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body">
                <div class="table-top">
                    <div class="iq-search-bar">
                        <el-form :model="searchForm" :inline="true">
                            <el-form-item>
                                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至"
                                    start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                                    :picker-options="pickerOptions0" size="small" style="margin-right: 10px" @change="search">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item>
                                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="姓名"
                                    @keyup.enter.native="search" clearable ></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" size="small" @click="search()">搜索</el-button>
                            </el-form-item>
                        </el-form>
                    </div>

                </div>
                <el-table :data="tableData" stripe style="width: 100%" height="calc(100% - 70px)" :header-cell-style="{background:'#F5F7FC'}">
                    <el-table-column prop="name" label="姓名">
                    </el-table-column>
                    <el-table-column prop="phone" label="账号" align="center">
                    </el-table-column>
                    <el-table-column prop="expend" label="支出" align="center">
                    </el-table-column>
                     <el-table-column prop="income" label="收入" align="center">
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex"
                    :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { STATS } from "../../../components/STATS/STATS"
import bread from "../../../components/common/breadcrumb";

export default {
    name: "membershipStatistics",
    components: {
        bread
    },
    data() {
        var statistics = new STATS(this.TokenClient, this.Services.Authorization)
        return {
            StatisticsDomain: statistics,
            tableData: [],
            Keyword: "",
            organizationId: this.$route.query.organizationId,
            PageIndex: 1,
            PageTotal: 1,
            PageSize: 15,
            DataTotal: 0,
            searchForm: {
                statrTime: '',
                endTime: '',
                keyWord: '',
                datepicker: ''
            },
            pickerOptions0: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        toConsumptionDetail(item,type) {
            var _this = this;
            _this.$router.push(
                {
                    path: '/STATS/ConsumptionDetail',
                    query: {
                        id: item.userId,
                        type:type
                    }
                }
            );
        },
        search() {
            this.PageIndex = 1;
            this.getList()
        },
        ChangePage(pageIndex) {
            this.PageIndex = pageIndex;
            this.getList();
        },
        getList() {
            var _this = this;
            var item = _this.searchForm;
            if (_this.searchForm.datepicker) {
                item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00';
                item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59';
            } else {
                item.startTime = "";
                item.endTime = "";
            }
            _this.StatisticsDomain.getOrgStatistics('1','0', item.startTime, item.endTime,item.keyWord, _this.PageIndex, 
                (data) => {
                    this.tableData = data.data.results;
                    this.PageIndex = data.data.pageIndex;
                    this.PageSize = data.data.pageSize;
                    this.PageTotal = data.data.pageCount;
                    this.DataTotal = data.data.dataTotal;
                }, (err) => {
                    console.log(err);
                })
        },
    }
}
</script>

<style scoped>
/*content*/


.table-top {
    margin-bottom: -10px;
}

.iq-search-bar {
    display: inline-block;
    margin-right: 20px;
}

.title-div {
    font-size: 16px;
    font-weight: 700;
    color: #303133;
}

.title-div .num {
    margin-left: 10px;
    font-size: 18px;
    color: red;
}

.iq-search-bar .searchbox {
    /* width: 480px; */
    position: relative;
}

.iq-search-bar .searchbox .search-link {
    position: absolute;
    right: 15px;
    top: 2px;
    font-size: 16px;
}

.iq-search-bar .search-input {
    width: 100%;
    padding: 5px 40px 5px 15px;
    border-radius: 10px;
    border: none;
    background: #ceebee;
    line-height: 1.8;
}

.table-img {
    position: relative;
    width: 200px;
    height: 120px;
    overflow: hidden;
}

.table-img img {
    width: 100%;
    height: auto;
}

.table-img p {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 35px;
    line-height: 35px;
    background-color: rgba(0, 0, 0, .5);
    font-size: 14px;
    color: #ffffff;
    text-align: center;
}

.table-info h4 {
    margin-bottom: 25px;
}

.table-info .el-row {
    margin-bottom: 15px;
}

.iq-search-bar .searchbox .el-input {
    width: 300px;
}

</style>
